import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Viewport from "../components/Viewport"

const Contact = () => (
  <Layout>
    <Seo title="Contacts" description="Emerald Company Contacts" />

    <section className="contact">
      <div className="description">
        <Viewport rootMargin="0px 0px -200px 0px">
          <h2>
            <span>Emerald</span> Contact
          </h2>

          <p className="large-text">
            EmeraldPay Ltd
            <br />
            86-90 Paul Street,
            <br />
            London, England, EC2A 4NE
            <br />
            <br />
          </p>

          <p className="medium-text">
            Have an issue with our software?&nbsp;
            <Link to="support">Contact Support</Link>
            <br />
            <br />
            Security report?{" "}
            <a href="mailto:security@emerald.cash">security@emerald.cash</a>
          </p>
        </Viewport>
      </div>
    </section>
  </Layout>
)

export default Contact
